export enum EsgNameEnum {
  COMPANY = 'Empresa',
  ENVIRONMENTAL = 'Ambiental',
  SOCIAL = 'Social',
  GOVERNANCE = 'Governança'
}

export enum EsgTypeEnum {
  COMPANY = 0,
  ENVIRONMENTAL,
  SOCIAL,
  GOVERNANCE
}
